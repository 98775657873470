import Button from 'components/home/common/Button'
import { useFormContext } from 'react-hook-form'
import { DestinationSearchFormFields } from 'components/home/navbar/destination/DestinationNavbar'
import ControlledNumberInput from 'components/form/Home/ControlledNumberInput'
import NavbarPopover from 'components/home/navbar/popovers/components/NavbarPopover'
import { PropertyNavbarFormFields } from 'components/property/PropertyNavbar'
import classNames from 'classnames'
import { useMemo } from 'react'
import NavbarButton from '../../common/NavbarButton'
import { AddCircleIconLarge, MinusCircleIconLarge, UserOutlineIcon } from 'components/icons'
import { joinBy, pluraliseWithNumber } from '@ama-selections/ui'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import Input from 'components/form/Home/Input'

interface GuestsSelectionPopoverProps {
  className?: {
    container?: string
    button?: string
    panel?: string
  }
}

const GuestsSelectionPopover = ({
  className,
}: GuestsSelectionPopoverProps) => {
  const { control, watch, formState: { errors } } = useFormContext<DestinationSearchFormFields | PropertyNavbarFormFields>()

  const { isMobile } = useScreenWidth(1024)

  const numberOfGuests = useMemo(() => {
    return (watch('adults') ?? 0) + (watch('children') ?? 0)
  }, [watch(['adults', 'children'])])

  return (
    <NavbarPopover
      trigger={
        isMobile
          ? (
            <Input
              variant="searchbar"
              placeholder="Guests"
              prefix={<UserOutlineIcon />}
              value={joinBy([
                pluraliseWithNumber(
                  watch('adults') ?? 0,
                  'Adult',
                  {
                    nullable: true,
                  },
                ),
                pluraliseWithNumber(
                  watch('children') ?? 0,
                  'Child',
                  {
                    pluralEnding: 'ren',
                    nullable: true,
                  },
                ),
              ], ', ')}
              readOnly
            />
          )
          : (
            <NavbarButton
              prefix={<MinusCircleIconLarge className="stroke-1 text-18 text-grey-250" />}
              suffix={<AddCircleIconLarge className="stroke-1 text-18 text-primary-fresh" />}
              active={numberOfGuests > 0}
              className={{
                container: classNames('min-w-[176px]', className?.container),
              }}
            >
              {pluraliseWithNumber(
                numberOfGuests,
                'Guest',
                { showPlaceholderOnNull: true },
              )}
            </NavbarButton>
          )}
      variant="absolute"
      className={{
        panel: classNames('px-40 max-w-sm min-w-[345px] flex flex-col items-center top-[65px] !pt-40 !pb-40', className?.panel),
        button: className?.button,
      }}
    >
      {({ close }) => (
        <>
          <ControlledNumberInput
            placeholder="0"
            label="Adults"
            sublabel="18 or older"
            control={control}
            name="adults"
            error={errors.adults}
            className={{ container: 'pb-24' }}
          />

          <ControlledNumberInput
            name="children"
            control={control}
            placeholder="0"
            label="Children"
            sublabel="Under 18"
            error={errors.children}
            className={{ container: 'pb-40' }}
          />

          <Button
            style="pill"
            onClick={close}
          >
            Confirm Guests
          </Button>
        </>
      )}
    </NavbarPopover>
  )
}

export default GuestsSelectionPopover
