import DayPicker, { DayPickerProps } from 'react-day-picker'
import classNames from 'classnames'

export interface DateRangePickerProps extends DayPickerProps {}

const DateRangePicker = ({
  ...props
}: DateRangePickerProps) => {
  return (
    <DayPicker
      {...props}
      className={classNames(
        props.className,
        'flex justify-center DayPickerPublic DayPickerPublicPopover',
      )}
      numberOfMonths={2}
      weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
    />
  )
}

export default DateRangePicker
