import { Controller, useFormContext } from 'react-hook-form'
import DateRangePicker, { DateRangePickerProps } from 'components/form/Home/DateRangePicker'
import { DayModifiers } from 'react-day-picker'
import { DateRangeInput } from 'components/dates/DateRangePicker'
import { ModifiersUtils } from 'react-day-picker'

interface ControlledDateRangePickerProps extends DateRangePickerProps {
  name: 'dates'
}

type ControlledDateRangePickerFormFields = {
  dates: DateRangeInput
}

const ControlledDateRangePicker = ({
  name,
  ...props
}: ControlledDateRangePickerProps) => {
  const { control } = useFormContext<ControlledDateRangePickerFormFields>()

  const onDayChange = (
    day: Date,
    modifiers: DayModifiers,
    value: DateRangeInput | undefined,
    onChange: (value: DateRangeInput) => void,
  ) => {
    if (modifiers?.disabled) {
      return
    }

    let startDate = value?.startDate
    let endDate = value?.endDate

    if (startDate && endDate) {
      startDate = undefined
      endDate = undefined
    }

    if (startDate && !endDate && props.disabledDays) {
      const dateToCheck = new Date(startDate)
      while (dateToCheck < day) {
        if (ModifiersUtils.dayMatchesModifier(dateToCheck, props.disabledDays)) {
          startDate = undefined
          endDate = undefined
          break
        }

        dateToCheck.setDate(dateToCheck.getDate() + 1)
      }
    }

    if (!startDate) {
      startDate = day
    } else if (!endDate) {
      if (day < startDate) {
        startDate = day
      } else {
        endDate = day
      }
    }

    onChange({ startDate, endDate })
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <DateRangePicker
          onDayClick={(day, modifiers) => onDayChange(day, modifiers, value, onChange)}
          selectedDays={{
            from: value?.startDate,
            to: value?.endDate,
          }}
          modifiers={{
            selected_start: value?.startDate,
            selected_end: value?.endDate,
            selected_range: {
              from: value?.startDate,
              to: value?.endDate,
            },
          }}
          initialMonth={value?.startDate ?? new Date()}
          onBlur={onBlur}
          {...props}
        />
      )}
    />
  )
}

export default ControlledDateRangePicker
