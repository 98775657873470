import { Share } from 'data/enums/share-target'
import { CopySimpleIcon, FacebookIcon, MailOutlineIcon, WhatsappIcon } from 'components/icons'
import { PropertyPageQuery } from 'gql/graphql'
import ShareModal from './home/ShareModal'

type PropTypes = {
  isOpen: boolean,
  onClose: () => void
  property: PropertyPageQuery['property']
}

const SharePropertyModal = ({
  isOpen,
  onClose,
  property,
}: PropTypes) => {
  if (!property) { return null }

  return (
    <ShareModal
      isOpen={isOpen}
      onClose={onClose}
      title="Share this property"
      property={property}
      links={[
        {
          icon: <FacebookIcon className="text-black text-22" />,
          target: Share.FACEBOOK,
          href: '/' + property.url,
        },
        {
          icon: <CopySimpleIcon className="text-black text-22" />,
          target: Share.CLIPBOARD,
          href: '/' + property.url,
        },
        {
          icon: <WhatsappIcon className="text-black text-22" />,
          target: Share.WHATSAPP,
          href: '/' + property.url,
        },
        {
          icon: <MailOutlineIcon className="text-black text-22" />,
          target: Share.EMAIL,
          href: '/' + property.url,
        },
      ]}
    >
    </ShareModal>
  )
}

export default SharePropertyModal
