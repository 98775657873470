import { useEffect, useRef, useState } from 'react'

export const usePopover = () => {
  const [isOpen, setIsOpen] = useState(false)
  const popoverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && event.target) {
        if (!popoverRef.current.contains(event.target as Node)) {
          setIsOpen(false)
        }
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('pointerdown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
      document.addEventListener('pointerdown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [isOpen, setIsOpen])

  return {
    popoverRef,
    isOpen,
    setIsOpen,
  }
}
