import classNames from 'classnames'
import Popover, { PopoverProps } from 'components/home/common/Popover'

interface NavbarPopoverProps extends PopoverProps {
  className?: {
    overlay?: string
    container?: string
    button?: string
    panel?: string
  }
  variant: 'fixed' | 'absolute'
}

const NavbarPopover = ({
  className,
  trigger,
  children,
  variant,
  ...props
}: NavbarPopoverProps) => {
  return (
    <Popover
      className={{
        overlay: className?.overlay,
        container: classNames(
          className?.container,
          'h-full',
          {
            '!static': variant === 'fixed',
          },
        ),
        button: classNames(
          className?.button,
          'flex focus:outline-none h-full',
        ),
        panel: classNames(
          className?.panel,
          'bg-white rounded-24 pt-15 pb-30 overflow-y-auto',
          {
            'fixed top-[67px] inset-x-[calc(50%-427px)] max-w-[830px] max-h-[70vh] w-full': variant === 'fixed',
            'absolute right-0 top-[calc(100%+12px)]': variant === 'absolute',
          },
        ),
      }}
      trigger={trigger}
      children={children}
      showOverlay
      {...props}
    />
  )
}

export default NavbarPopover
