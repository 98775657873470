import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { isServer } from 'data/helpers/ssr'
import { usePopover } from 'data/hooks/usePopover'
import { ReactChild } from 'data/types/types'
import { createPortal } from 'react-dom'

export type ControlledNavbarPopoverRenderProps = ReactChild
  | (({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: (v: boolean) => void }) => ReactChild)

export interface ControlledNavbarPopoverProps {
  className?: {
    container?: string
    button?: string
    panel?: string
  }
  trigger: ControlledNavbarPopoverRenderProps
  children: ControlledNavbarPopoverRenderProps
}

const ControlledNavbarPopover = ({
  className,
  trigger,
  children,
}: ControlledNavbarPopoverProps) => {
  const { popoverRef, isOpen, setIsOpen } = usePopover()

  const renderItem = (item: ControlledNavbarPopoverRenderProps) => {
    if (typeof item === 'function') {
      return item({ isOpen, setIsOpen })
    }

    return item
  }

  return (
    <div className={classNames(className?.container)}>
      {
        (isOpen && !isServer) && (
          createPortal(
            <div
              aria-hidden={true}
              className="fixed inset-0 z-10 bg-black bg-opacity-10"
            />,
            document.body,
          )
        )
      }

      <div
        ref={popoverRef}
        className={classNames(
          className?.button,
          'flex items-center h-full',
        )}
      >
        {renderItem(trigger)}

        <Transition
          as="div"
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
          className={classNames(
            'top-[calc(100%+12px)] absolute inset-x-0 max-w-[830px] min-h-[500px] max-h-[70vh] w-full mx-auto',
            'bg-white rounded-24 z-40',
            className?.panel,
          )}
        >
          {renderItem(children)}
        </Transition>
      </div>
    </div>
  )
}

export default ControlledNavbarPopover
