import React, { Fragment } from 'react'
import { Popover as HeadlessPopover, Transition } from '@headlessui/react'
import { ReactChild } from 'data/types/types'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { isServer } from 'data/helpers/ssr'
import { ReactTag } from '@headlessui/react/dist/types'

export type PopoverRenderProps = ReactChild
  | (({ open, close }: { open: boolean, close: () => void}) => ReactChild)

export interface PopoverProps {
  className?: {
    container?: string
    overlay?: string
    button?: string
    panel?: string
  }
  trigger: PopoverRenderProps
  children: PopoverRenderProps
  showOverlay?: boolean
  disabled?: boolean
}

const Popover = ({
  className,
  trigger,
  children,
  showOverlay = false,
  disabled = false,
}: PopoverProps) => {
  const renderItem = (item: PopoverRenderProps, open: boolean, close: () => void) => {
    if (typeof item === 'function') {
      return item({ open, close })
    }

    return item
  }

  return (
    <HeadlessPopover className={classNames(className?.container, 'relative')}>
      {({ open, close }) => (
        <>
          {(showOverlay && !isServer) && (
            createPortal(
              <HeadlessPopover.Overlay className={classNames(
                'fixed inset-x-0 bottom-0 z-10 bg-black bg-opacity-10 top-0',
                className?.overlay,
              )} />,
              document.body,
            )
          )}

          <HeadlessPopover.Button
            className={classNames(className?.button, 'flex focus:outline-none')}
            disabled={disabled}
          >
            {renderItem(trigger, open, close)}
          </HeadlessPopover.Button>

          <Transition
            as={Fragment as ReactTag}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <HeadlessPopover.Panel className={classNames(className?.panel, 'absolute z-40')}>
              {renderItem(children, open, close)}
            </HeadlessPopover.Panel>
          </Transition>
        </>
      )}

    </HeadlessPopover>
  )
}

export default Popover
